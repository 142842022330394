// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule } from '@azure/msal-angular';
import { SharedModuleModule } from './shared-module/shared-module.module';

import { DatePipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';

// Components
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

// Ngx Modules
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { NGXModule } from './ngx.module';

// Pipe
import { ReplacePipe } from './helpers/replace-pipe';

// PrimeNG Module
import { PrimeNGModule } from './primeNG.module';

@NgModule({
  declarations: [
    AppComponent,
    ReplacePipe,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SharedModuleModule,
    ReactiveFormsModule,
    PrimeNGModule,
    NGXModule,
    ToastrModule.forRoot({ timeOut: 3000, enableHtml: true }),
    NgxMaskModule.forRoot(),
    MsalModule.forRoot(
      {
        auth: {
          clientId: '8870b782-a80e-47b1-9b0d-77576cdc2751',

          /* Development */
          // redirectUri: 'http://localhost:4200',
          // postLogoutRedirectUri: 'http://localhost:4200',

          // /* Staging */
          redirectUri: 'https://dashboard-staging.domii.io',
          postLogoutRedirectUri: 'https://dashboard-staging.domii.io',

          /* Production */
          // redirectUri: 'https://dashboard.domii.io',
          // postLogoutRedirectUri: 'https://dashboard.domii.io',

          validateAuthority: false,
          navigateToLoginRequestUrl: true,
        },
        framework: {
          isAngular: true,
        },
        cache: {
          cacheLocation: 'sessionStorage',
        },
      },
      {
        consentScopes: [
          'https://ia9b2c.onmicrosoft.com/backend/user_impersonation',
        ],
      }
    ),
  ],
  providers: [DatePipe, CurrencyPipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
