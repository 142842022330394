import { Injectable, EventEmitter } from '@angular/core';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { TokenService } from '../token/token.service';
import { Account } from 'msal';
import { Router } from '@angular/router';
import { ProfessionalService } from '../professional/professional.service';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  readonly signupAuthority = "https://ia9b2c.b2clogin.com/tfp/ia9b2c.onmicrosoft.com/b2c_1_domii_register_web";
  readonly signinAuthority = "https://ia9b2c.b2clogin.com/tfp/ia9b2c.onmicrosoft.com/b2c_1_domii_signin_web";
  readonly passwordResetAuthority = "https://ia9b2c.b2clogin.com/tfp/ia9b2c.onmicrosoft.com/b2c_1_domii_reset_web"
  readonly scope = "https://IA9B2C.onmicrosoft.com/backend/user_impersonation";
  resetPwdUrl = "https://ia9b2c.b2clogin.com/IA9B2C.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_domii_reset_web&client_id=063ef5ba-fa4b-419f-914c-34c8f73816fa&nonce=defaultNonce&redirect_uri=" +
    "https%3A%2F%2Fjwt.ms" +
    "&scope=openid&response_type=id_token&prompt=login";
  token: string = "";
  userObject: Account;
  profileInfo: any = null;
  profileUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private msalService: MsalService,
    private broadcastService: BroadcastService,
    private tokenService: TokenService,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router) {
    this.msalService.handleRedirectCallback((authError, response) => {
      // check if msal error set
      if (authError) {
        // check if error code for forgot password is there
        if (authError.errorMessage.indexOf("AADB2C90118") > -1) {
          // change authority to password reset policy
          console.log(authError.errorMessage)
          this.msalService.loginRedirect({
            authority: this.passwordResetAuthority
          });
          return;
        }
      }
    });

    if (this.msalService.getAccount()) {
      this.msalService.acquireTokenSilent({
        scopes: [this.scope],
        authority: this.signinAuthority,
        account: this.msalService.getAccount()
      });
    }

    this.broadcastService.subscribe("msal:acquireTokenSuccess", payload => {
      // do something here
      console.log('tokensuccess');
      this.token = payload.accessToken;
      this.tokenService.token = payload.accessToken;
      this.userObject = this.msalService.getAccount();
      this.tokenService.userCache = this.msalService.getAccount();
      this.checkForProfileInfo();
    });

    this.broadcastService.subscribe("msal:loginFailure", payload => {
      console.log('loginfailure')
      // do something here
      if (
        payload._errorDesc &&
        payload._errorDesc.indexOf("AADB2C90118") !== -1
      ) {
        window.location.href = "https://ia9b2c.b2clogin.com/ia9b2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_domii_reset_web&client_id=063ef5ba-fa4b-419f-914c-34c8f73816fa&nonce=defaultNonce&redirect_uri=" + "https%3A%2F%2Fjwt.ms" + "&scope=openid&response_type=id_token&prompt=login";
      }
    });

    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      // do something here
      this.router.navigate(['admin']);
    });
  }

  checkForProfileInfo() {
    // if there's no profile, go get it
    if (this.profileInfo == null) {
      this.professionalService.getUserProfile(this.environmentService.getIA9CoreUrl() + 'User').subscribe(
        response => {
          this.profileInfo = response;
          this.profileUpdated.emit(this.profileInfo);
        },
        error => {
          this.profileUpdated.emit(null);
        }
      );
    } else {
      // publish the fact that there is a profile info, don't just fetch it again
      this.profileUpdated.emit(this.profileInfo);
    }
  }

  login() {
    this.loginAndSignup(this.signinAuthority);
  }

  loginAndSignup(auth: string) {
    this.msalService.loginRedirect({
      scopes: [this.scope],
      authority: auth
    });
  }

  logout() {
    this.msalService.authority = this.signupAuthority;
    this.msalService.logout();
  }

  resetPassword() {
    window.location.href = this.resetPwdUrl;
  }
}
