import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProfessionalType } from '../../models/professionalTypeModel';
import { map } from 'rxjs/operators';
import { TokenService } from '../token/token.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalService {
  realtorProfile: EventEmitter<any> = new EventEmitter<any>();
  private openCancelPlan = new BehaviorSubject<any>({
    open: false,
    profile: null,
  });

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  emitRealtorProfile(value) {
    this.realtorProfile.emit(value);
  }

  emitCancelPlan(userProfile) {
    this.openCancelPlan.next({ open: true, profile: userProfile });
  }

  getCancelPlan() {
    return this.openCancelPlan.asObservable();
  }

  getMembershipTypes(url) {
    return this.http.get<ProfessionalType[]>(url);
  }

  getMLSAffiliates(url) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers });
  }

  getClientAuthToken(url, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http
      .get(url, { headers: headers })
      .pipe(map((res) => res as any));
  }

  registerProfessional(url, body, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http.post(url, body, { headers: headers });
  }

  updatePhoto(url, body) {
    const headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.tokenService.token
    );
    return this.http.post(url, body, { headers, responseType: 'text' });
  }

  getUserProfile(url) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.tokenService.token);
    return this.http.get(url, { headers });
  }

  getUserInfo(url, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers });
  }

  getWidgetInfo(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  getALlListing(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  updateUserProfile(url, body) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.tokenService.token);
    return this.http.post(url, body, { headers });
  }

  // Retrieve Membership Plans for Professionals

  getMembershipPlans(url, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http
      .get(url, { headers: headers })
      .pipe(map((res) => res as any));
  }

  getMembershipPlansRealtors(url, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http
      .get(url, { headers: headers })
      .pipe(map((res) => res as any));
  }

  getTestimonialList(url, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http
      .get(url, { headers: headers })
      .pipe(map((res) => res as any));
  }

  postTestimonialList(url, body, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http.post(url, body, { headers: headers });
  }

  getValueMiiListing(url, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http
      .get(url, { headers: headers })
      .pipe(map((res) => res as any));
  }
  getLeads(url, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers });
  }

  getValidEmail(url, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http
      .get(url, { headers: headers })
      .pipe(map((res) => res as any));
  }

  getValidateInvitationCode(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }
}
