import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpClient,
    private tokenService: TokenService) { }

  getCompany(url, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers });
  }

  getMemberships(url, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers });
  }

  getCompanyUsers(url, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  updateCompanyUser(url, clientId, token, body) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.post(url, body, { headers });
  }

  deleteCompanyUser(url, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.delete(url, { headers });
  }

  updateComapny(url, clientId, body, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.post(url, body, { headers });
  }
}
