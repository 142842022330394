<nav class="navbar navbar-dark bg-dark header">
    <div class="navbar-company" routerLink="/admin" *ngIf="router.url === '/admin' || router.url === '/admin/users'">
        <h1>Admin</h1>
    </div>
    <div class="navbar-company" *ngIf="router.url === '/admin/company-info' || router.url === '/admin/company-users'">
        <h1> {{ companyName }}</h1>
    </div>
    <ul class="header_menu" *ngIf="router.url === '/admin'">
        <li>
            <div class="dropdown">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                    <img style="height: 40px;" src="../../../../assets/images/prof_landing.svg" alt="">
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" routerLink="/auth/logout">
                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                        <span>Logout</span>
                    </a>
                </div>
            </div>
        </li>
    </ul>
    <ul class="header_menu" *ngIf="router.url === '/admin/company-info'">
        <li>
            <div class="dropdown">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                    <img style="height: 40px;" src="../../../../assets/images/prof_landing.svg" alt="">
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" routerLink="/auth/logout">
                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                        <span>Logout</span>
                    </a>
                </div>
            </div>
        </li>
    </ul>
    <div class="navbar-toggler content-mobile" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
        *ngIf="router.url === '/admin' || router.url === '/admin/company-info'">
        <img src="../../../assets/images/menu-toggler.svg">
    </div>
</nav>
<div class="content-desktop" *ngIf="router.url === '/admin' || router.url === '/admin/company-info'">
    <div class="desktopMenu">
        <app-sidebar></app-sidebar>
    </div>
</div>
<div class="content-mobile" *ngIf="router.url === '/admin'  || router.url === '/admin/company-info'">
    <div class="mobileMenu collapse" id="navbarNav">
        <div class="toggler">
            <img src="../../../assets/images/cross.svg" data-toggle="collapse" data-target="#navbarNav">
        </div>
        <app-sidebar></app-sidebar>
        <div id="greyOutContent"></div>
    </div>
</div>