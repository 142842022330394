import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModel } from 'src/app/models/menuModel';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  menuList: MenuModel[] = [];
  showPlanAlert = false;
  companyName:any;

  constructor(
    private loginService: LoginService,
    public router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void { 
    this.companyName = sessionStorage.getItem("companyName");
  }

}
