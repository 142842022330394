// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  environmentName: 'dev',
  IA9CoreUrl: "https://ia9core-dev.azurewebsites.net/api/",
  IA9CoreKey: "PQfl6RyG62n176NdcZW2s9p8NYAKoFmoDp0rg/T4wThDl07TKIxvOw==",
  IA9V2Url: 'https://ia9v2-dev.azurewebsites.net/api/',
  IA9V2Key: 'LZz3ox8YDY8GQ8nXH3d7/Ve6ZcBnG3ay0VH7H/hTHXdMltdm4y/rig==',
  IA9LightningUrl: 'https://ia9lightning-dev.azurewebsites.net/api/',
  IA9LightningKey: 'yIcsZ9MioJPgcHQoCDUAaS6EyuG/4odgd7cWniq0tM4OfFxYR0favQ==',
  XClientId_Domii: 'FEE8CC43-AFD8-4DA8-A4E3-6639745C4428',
  XClientId_CoCoEarly: '756A8E40-4230-44FF-8D7D-DA2D81B4E924',
  EULA_CoCoEarly: 'https://ia9incextra.blob.core.windows.net/cocoearly/cea-app-eula-v1.html',
  Privacy_CoCoEarly: 'https://ia9incextra.blob.core.windows.net/cocoearly/cea-privacy-v1.html',
  EULA_Domii: 'https://ia9incextra.blob.core.windows.net/blob/mobile_app_eula_v3.html',
  Privacy_Domii: 'https://ia9incextra.blob.core.windows.net/blob/ia9-privacy-v3.html',
  HubspotSignupFormUrl: 'https://api.hsforms.com/submissions/v3/integration/submit/19950207/54ca0463-43a2-4b17-8090-5ee14fab84dd',
  HubspotCancelPlanRequestFormUrl: 'https://api.hsforms.com/submissions/v3/integration/submit/19950207/a629fac1-8d5d-403c-b51c-9c7f78351e98'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.