export class CompanyOnboardingModel {
    CompanyName: string;
    CompanyCode: string;
    Address: string;
    MobilePhoneNumber: string;
    OfficePhoneNumber: string;
    Website: string;
    FaxNumber: string;
    CompanyEmailAddress: string;
    CompanyLogoFile: CompanyLogoFileModel;
    FirstName: string;
    LastName: string;
    AdminEmailAddress: string;
    AdminPhoneNumber: number;
    Title: string;
    FacebookUrl: string;
    InstagramUrl: string;
    TwitterUrl: string;
    YoutubeUrl: string;
    PintrestUrl: string;
    ImageLogoUrl: string;
}

export class CompanyLogoFileModel {
    description: string;
    fileName: string;
    docType: string;
    base64: string;
}
