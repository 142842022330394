<div *ngIf="router.url === '/admin'">
    <div class="column">
        <div class="content two">
            <div class="text-left">
                <ul class="nav navbar-nav" class="padnull listStyleNone">
                    <p-accordion class="accordion_set">
                        <p-accordionTab header="Dashboard" [selected]="true">
                            <li class="menuList" routerLink="/auth/logout">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Logout</span>
                                </div>
                            </li>
                            <p-menu #menu1 [popup]="true" [model]="items"></p-menu>
                        </p-accordionTab>
                    </p-accordion>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="router.url === '/admin/company-info'">
    <div class="column">
        <div class="content two">
            <div class="text-left">
                <ul class="nav navbar-nav" class="padnull listStyleNone">
                    <p-accordion class="accordion_set">
                        <p-accordionTab header="Dashboard" [selected]="true">
                            <li class="menuList" routerLink="/admin/company-users">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Company User's</span>
                                </div>
                            </li>
                            <li class="menuList" (click)="companyProfilePopup()">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Company Info</span>
                                </div>
                            </li>
                            <li class="menuList" (click)="socialPopup()">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Social Info</span>
                                </div>
                            </li>
                            <li class="menuList" (click)="logout()">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Logout</span>
                                </div>
                            </li>
                            <p-menu #menu1 [popup]="true" [model]="items"></p-menu>
                        </p-accordionTab>
                    </p-accordion>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- company-info popup -->
<section *ngIf="showCompanyProfilePopup">
    <!-- start -->
    <p-dialog header="Company Info" [modal]="true" [(visible)]="showCompanyProfilePopup"
        [style]="{width: '575px', padding: '10px', height: '500px'}" [baseZIndex]="10000" position="top"
        [draggable]="false" [resizable]="true" [breakpoints]="{'960px': '100vw', '640px': '100vw'}"
        contentStyleClass="admin-company-dialog">
        <div class="">
            <form [formGroup]="companyProfileForm">
                <!-- row 1 -->
                <div class="row">
                    <!-- Company Name -->
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="companyName">Company Name</label>
                        <input type="text" class="form-control" formControlName="companyName" id="companyName"
                            placeholder="Enter Company Name"
                            [ngClass]="{'is-invalid': (submitted || form.companyName.touched) && form.companyName.errors}">
                        <div class="invalid-feedback" *ngIf="(submitted || form.companyName.touched)
                        && form.companyName.invalid && form.companyName.errors?.required">
                            <span>Please enter company name</span>
                        </div>
                    </div>
                    <!-- Address -->
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="address">Address</label>
                        <input type="text" class="form-control" formControlName="address" id="address"
                            placeholder="Enter Full Address"
                            [ngClass]="{'is-invalid': (submitted || form.address.touched) && form.address.errors}">
                        <div class="invalid-feedback" *ngIf="(submitted || form.address.touched)
                    && form.address.invalid && form.address.errors?.required">
                            <span>Please enter full address</span>
                        </div>
                    </div>
                </div>
                <!-- row 2 -->
                <div class="row">
                    <!-- Telephone Number -->
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="telno">Telephone Number</label>
                        <input class="form-control" formControlName="telno" id="telno"
                            placeholder="Enter Telephone Number"
                            [ngClass]="{'is-invalid': (submitted || form.telno.touched) && form.telno.errors}">
                        <div class="invalid-feedback" *ngIf="(submitted || form.telno.touched) && form.telno.invalid">
                            <div *ngIf="form.telno.errors?.required">Please enter telephone number</div>
                            <div *ngIf="form.telno.errors?.pattern">Please enter 10 digit telephone number</div>

                        </div>
                    </div>
                    <!-- Website -->
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="website">Website</label>
                        <input type="website" class="form-control" formControlName="website" id="website"
                            placeholder="Enter Website URL"
                            [ngClass]="{'is-invalid': (submitted || form.website.touched) && form.website.errors}">
                        <div class="invalid-feedback" *ngIf="(submitted || form.website.touched)
                    && form.website.invalid && form.website.errors?.required">
                            <span>Please web url</span>
                        </div>
                    </div>
                </div>
                <!-- row 3 -->
                <div class="row">
                    <!-- Fax Number -->
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="fax">Fax Number</label>
                        <input type="number" class="form-control" formControlName="fax" id="fax"
                            placeholder="Enter Fax no"
                            [ngClass]="{'is-invalid':(submitted || form.fax.touched) && form.fax.errors}">
                        <div class="invalid-feedback" *ngIf="(submitted || form.fax.touched)
                    && form.fax.invalid && form.fax.errors?.required">
                            <span>Please enter fax number</span>
                        </div>
                    </div>
                    <!-- Email Address -->
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="email">Email Address</label>
                        <input type="email" class="form-control" formControlName="email" id="email"
                            placeholder="Enter Email Address"
                            [ngClass]="{'is-invalid': (submitted || form.email.dirty || form.email.touched) && form.email.errors}"
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
                        <div class="invalid-feedback"
                            *ngIf="(submitted || form.email.dirty || form.email.touched) && form.email.errors">
                            <span *ngIf="form.email.errors.required">Please enter email</span>
                            <span *ngIf="form.email.errors.pattern">Please enter valid email</span>
                        </div>
                    </div>
                </div>
                <!-- row 4 -->
                <div class="row">
                    <!-- company logo -->
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="logo">Company Logo</label>
                        <div class='file'>
                            <input type="file" #file class="form-control" id="logo" placeholder="Upload Company Logo"
                                [ngClass]="{'is-invalid': (submitted || form.logo.touched) && form.logo.invalid}"
                                (change)="onFileChange($event)" hidden formControlName="logo"
                                accept="image/jpg,image/jpeg" />
                            <div class="imageContainer">
                                <img style="cursor: pointer;" [src]="imageSrc" *ngIf="imageSrc" class="previewImg">
                                <img style="cursor: pointer;" src="../../../assets/images/profileImg.svg"
                                    *ngIf="!imageSrc" class="previewImg">
                                <button (click)="selectImage()" class="upload">Upload</button>
                            </div>
                            <!-- <div class="invalid-feedback" *ngIf="(submitted || form.logo.touched) && form.logo.invalid">
                                <div *ngIf="form.logo.errors?.required">Please upload a logo</div>
                            </div> -->
                        </div>
                    </div>
                    <!-- company code -->
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="companycode">Company Code</label>
                        <input class="form-control" formControlName="companycode" id="companycode" [readonly]="true">
                    </div>
                </div>
            </form>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="showCompanyProfilePopup = false;"></button>
            <button pButton pRipple label="Update" iclass="p-button-text" (click)="onSubmit()"></button>
        </ng-template>
    </p-dialog>
    <!-- end -->
</section>

<!-- Social Popup -->
<section *ngIf="showSocialPopup">
    <p-dialog header="Social Info" [modal]="true" [(visible)]="showSocialPopup"
        [style]="{width: '575px', padding: '10px', height: '500px'}" [baseZIndex]="10000" position="top"
        [draggable]="false" [resizable]="true" [breakpoints]="{'960px': '100vw', '640px': '100vw'}"
        contentStyleClass="admin-company-dialog">
        <form [formGroup]="socialForm">
            <div class="row">
                <div class="form-group col-md-2">
                    <img src="../../../assets/images/facebook.svg" alt="facebook" class="form-logo">
                </div>
                <div class="form-group col-md-10">
                    <input type='text' class="form-control social" formControlName="facebook" id="logo"
                        placeholder="Facebook"
                        [ngClass]="{'is-invalid': (submitted || social.facebook.touched) && social.facebook.invalid}" />
                    <div class="invalid-feedback" *ngIf="submitted && social.facebook.errors?.required">
                        <span class="error-msg">Please enter facebook URL.</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-2">
                    <img class="form-logo" src="../../../assets/images/instagram.svg" alt="instagram" />
                </div>
                <div class="form-group col-md-10">
                    <input type="text" class="form-control social" formControlName="instagram" placeholder="Instagram"
                        [ngClass]="{'is-invalid': submitted && social.instagram.errors}">
                    <div class="invalid-feedback" *ngIf="submitted && social.instagram.errors?.required">
                        <span class="error-msg">Please enter the instagram URL</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-2">
                    <img class="form-logo" src="../../../assets/images/twitter.svg" alt="twitter" />
                </div>
                <div class="form-group col-md-10">
                    <input type="text" class="form-control social" formControlName="twitter" placeholder="Twitter"
                        [ngClass]="{'is-invalid': submitted && social.twitter.errors}">
                    <div class="invalid-feedback" *ngIf="submitted && social.twitter.errors?.required">
                        <span class="error-msg">Please enter the twitter URL</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-2">
                    <img class="form-logo" src="../../../assets/images/youtube.svg" alt="youtube" />
                </div>
                <div class="form-group col-md-10">
                    <input type="text" class="form-control social" formControlName="youtube" placeholder="Youtube"
                        [ngClass]="{'is-invalid': submitted && social.youtube.errors}">
                    <div class="invalid-feedback" *ngIf="submitted && social.youtube.errors?.required">
                        <span class="error-msg">Please enter the youtube URL</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-2">
                    <img class="form-logo" src="../../../assets/images/pinterest.svg" alt="pinterest" />
                </div>
                <div class="form-group col-md-10">
                    <input type="text" class="form-control social" formControlName="pinterest" placeholder="Pinterest"
                        [ngClass]="{'is-invalid': submitted && social.pinterest.errors}">
                    <div class="invalid-feedback" *ngIf="submitted && social.pinterest.errors?.required">
                        <span class="error-msg">Please enter the pinterest URL</span>
                    </div>
                </div>
            </div>
        </form>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="showSocialPopup = false;"></button>
            <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-text"
                (click)="submitSocialForm()"></button>
        </ng-template>
    </p-dialog>
</section>
