import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from 'src/app/services/company/company.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { CompanyLogoFileModel, CompanyOnboardingModel } from 'src/app/models/companyOnboardingModel';
import { UpdateSocialLinksModel } from 'src/app/models/updateSocialLinksModel';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  items: MenuItem[];
  showCompanyProfilePopup: boolean = false;
  showSocialPopup: boolean = false;
  submitted: boolean = false;
  companyProfileForm: FormGroup;
  socialForm: FormGroup;
  fileName: any;
  membershipId: any;
  updatingCompanyProfileForm: boolean;
  updatingSocialForm: boolean;
  token: string = '';
  imageSrc: string;
  xClientId: any = '';
  compnayProfile: any;
  imageChanged: boolean = false;
  @ViewChild('file') file: ElementRef;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private environmentService: EnvironmentService,
    private companyService: CompanyService
  ) {
    this.token = sessionStorage.getItem('msal.idtoken');
    this.xClientId = sessionStorage.getItem("companyId");
  }

  ngOnInit(): void { }

  companyProfilePopup() {
    this.spinner.show();
    this.membershipId = sessionStorage.getItem("companyId");
    let url = this.environmentService.getIA9LightningUrl() + 'whitelabel/membershipsget/' + `${this.membershipId}` + '?code=' + this.environmentService.getIA9LightningKey();
    this.companyService.getMemberships(url, this.token).subscribe(response => {
      this.updatingCompanyProfileForm = true;
      this.showCompanyProfilePopup = true;
      this.initializecompanyProfileForm(response['data']);
      this.compnayProfile = response['data']
      this.imageSrc = response['data'].imageLogoUrl;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  initializecompanyProfileForm(companyDetails) {
    this.companyProfileForm = this.fb.group({
      companyName: new FormControl(this.updatingCompanyProfileForm ? companyDetails.companyName : "", [Validators.required]),
      address: new FormControl(this.updatingCompanyProfileForm ? companyDetails.address : "", [Validators.required]),
      telno: new FormControl(this.updatingCompanyProfileForm ? companyDetails.officePhoneNumber : "", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      website: new FormControl(this.updatingCompanyProfileForm ? companyDetails.websiteUrl : "", [Validators.required]),
      fax: new FormControl(this.updatingCompanyProfileForm ? companyDetails.faxNumber : "", [Validators.required]),
      email: new FormControl(this.updatingCompanyProfileForm ? companyDetails.email : "", [Validators.required, Validators.email]),
      logo: new FormControl(this.updatingCompanyProfileForm ? this.imageSrc : ""),
      companycode: new FormControl(companyDetails.code),
    });
  }

  get form() { return this.companyProfileForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.companyProfileForm.invalid) {
      return;
    }
    this.updateCompany();
    this.showCompanyProfilePopup = false;
  }

  socialPopup() {
    this.spinner.show();
    this.membershipId = sessionStorage.getItem("companyId");
    let url = this.environmentService.getIA9LightningUrl() + 'whitelabel/membershipsget/' + `${this.membershipId}` + '?code=' + this.environmentService.getIA9LightningKey();
    this.companyService.getMemberships(url, this.token).subscribe(response => {
      this.updatingSocialForm = true;
      this.showSocialPopup = true;
      this.initialieSocialForm(response['data']);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  initialieSocialForm(companyDetails) {
    this.socialForm = this.fb.group({
      'facebook': [this.updatingSocialForm ? companyDetails.facebookUrl : ""],
      'instagram': [this.updatingSocialForm ? companyDetails.instagramUrl : ""],
      'twitter': [this.updatingSocialForm ? companyDetails.twitterUrl : ""],
      'youtube': [this.updatingSocialForm ? companyDetails.youtubeUrl : ""],
      'pinterest': [this.updatingSocialForm ? companyDetails.pintrestUrl : ""],
      'membershipId': [this.updatingSocialForm ? companyDetails.uid : ""]
    });
  }

  get social() { return this.socialForm.controls; }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.fileName = file.name;
      const index = this.fileName.lastIndexOf('.') + 1;
      const ext = this.fileName.substr(index, this.fileName.length).toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg') {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = (reader.result as string).replace('jpeg', 'jpg');
          this.imageChanged = true;
        };
      }
    }
  }

  selectImage() {
    this.file.nativeElement.click();
  }

  submitSocialForm() {
    this.submitted = true;
    if (this.socialForm.invalid) return;
    this.updateSocial();
    this.socialForm.reset();
    this.showSocialPopup = false;
  }

  updateSocial() {
    let updateCompanyModel = new UpdateSocialLinksModel();
    updateCompanyModel.FacebookUrl = this.socialForm.get('facebook').value;
    updateCompanyModel.InstagramUrl = this.socialForm.get('instagram').value;
    updateCompanyModel.TwitterUrl = this.socialForm.get('twitter').value;
    updateCompanyModel.YoutubeUrl = this.socialForm.get('youtube').value;
    updateCompanyModel.PintrestUrl = this.socialForm.get('pinterest').value;
    updateCompanyModel.MembershipId = this.socialForm.get('membershipId').value;
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'whitelabel/Membershipupdatesociallinks?code=' + this.environmentService.getIA9LightningKey();
    this.companyService.updateComapny(url, this.xClientId, updateCompanyModel, this.token).subscribe(response => {
      if (response['isSuccess'] === true) {

      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();

    });
  }

  updateCompany() {
    const updateCompanyModel = new CompanyOnboardingModel();

    updateCompanyModel.CompanyName = this.companyProfileForm.get('companyName').value;
    updateCompanyModel.CompanyCode = this.companyProfileForm.get('companycode').value;
    updateCompanyModel.Address = this.companyProfileForm.get('address').value;
    updateCompanyModel.OfficePhoneNumber = this.companyProfileForm.get('telno').value;
    updateCompanyModel.Website = this.companyProfileForm.get('website').value;
    updateCompanyModel.FaxNumber = this.companyProfileForm.get('fax').value;
    updateCompanyModel.CompanyEmailAddress = this.companyProfileForm.get('email').value;

    updateCompanyModel.CompanyLogoFile = new CompanyLogoFileModel();
    updateCompanyModel.CompanyLogoFile.description = 'company logo';
    updateCompanyModel.CompanyLogoFile.fileName = this.fileName;
    updateCompanyModel.CompanyLogoFile.docType = 'Logo';

    if(this.imageChanged){
    updateCompanyModel.CompanyLogoFile.base64 = this.imageSrc;
    }

    updateCompanyModel.FacebookUrl = this.compnayProfile.facebookUrl;
    updateCompanyModel.InstagramUrl = this.compnayProfile.instagramUrl;
    updateCompanyModel.TwitterUrl = this.compnayProfile.twitterUrl;
    updateCompanyModel.YoutubeUrl = this.compnayProfile.youtubeUrl;
    updateCompanyModel.PintrestUrl = this.compnayProfile.pintrestUrl;
    updateCompanyModel.FacebookUrl = this.compnayProfile.facebookUrl;
    updateCompanyModel.ImageLogoUrl = this.compnayProfile.imageLogoUrl;
    updateCompanyModel.MobilePhoneNumber = this.compnayProfile.mobilePhoneNumber
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'whitelabel/membershipupdate?code=' + this.environmentService.getIA9LightningKey();
    this.companyService.updateComapny(url, this.xClientId, updateCompanyModel, this.token).subscribe(response => {
      if (response['isSuccess'] === true) {

      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();

    });
  }



  logout() {
    this.router.navigate(['/auth/logout']);
  }
}
