import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { CommonConstants } from 'src/app/constants/commonConstants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  androidStoreUrl: string;
  iOSStoreUrl: string;
  year: string;
  constructor(private environmentService: EnvironmentService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    // (document.getElementById('footerBrand') as HTMLImageElement).src = CommonConstants.imageBasePath + this.environmentService.getCompanyBrand();
    this.androidStoreUrl = this.environmentService.getAndroidStoreUrl();
    this.iOSStoreUrl = this.environmentService.getiOSStoreUrl();
    this.year = this.datePipe.transform(new Date(), 'yyyy');
  }

  openAppStore() {
    window.open(this.iOSStoreUrl);
  }

  openPlayStore() {
    window.open(this.androidStoreUrl);
  }

}
